export const DEV_URL = '';
export const PROD_URL = 'https://api.sordinals.io/api/v1/';

export enum OrderStatus {
  CREATED = 'Created',
  BUY_IN_PROGRESS = 'Buying in progress',
  COMPLETED = 'Completed',
  CANCELLING_IN_PROGRESS = 'Cancelling in progress',
  CANCELLED = 'Cancelled',
}

export enum MarketplaceRole {
  BUYER = 'buyer',
  SELLER = 'seller',
}
