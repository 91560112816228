import React from 'react';
import classes from './FiltersStyles.module.scss';
import classNames from 'classnames';
import { inscriptionContentMapData } from '../../constants';

type Props = {
  selected: string;
  onClick: (item: string) => void;
  className?: string;
};
export const Filters = ({ selected, onClick, className }: Props) => {
  const filterTags = Array.from(
    new Set(
      inscriptionContentMapData.map((item) => item.inscriptionType.toString())
    )
  );
  // filterTags.splice(1, 0, 'GIF');
  filterTags.unshift('No text');
  filterTags.unshift('All');
  return (
    <div className={classNames(classes.component, className || '')}>
      {filterTags.map((tag) => (
        <div
          key={tag}
          className={classNames(
            classes.item,
            selected === tag && classes.itemSelected
          )}
          onClick={() => onClick(tag)}
        >
          {tag}
        </div>
      ))}
    </div>
  );
};
