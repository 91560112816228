import React, { useEffect, useState } from 'react';
import classes from './TransferModalSyles.module.scss';
import { BasicModal } from '../../BasicModal/BasicModal';
import { BigPurpuleButton } from '../../Buttons/BigPurpuleButton';
import { BasicInput } from '../../Inputs/BasicInput/BasicInput';
import { validateStacksAddress } from 'micro-stacks/crypto';
import {
  resolveBnsName,
  resolveStxsName,
  shortenText,
} from '../../../utils/utils';
import { UseBlockchainCalls } from '../../../utils/useBlockchainCalls';
import useData from '../../../hooks/useData';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  onFailure: () => void;
  inscriptionHash: string;
};
export const TransferModal = ({
  onClose,
  inscriptionHash,
  onSuccess,
  onFailure,
}: Props) => {
  const [destination, setDestination] = useState('');
  const [destinationError, setDestinationError] = useState('');
  const [bnsContent, setBnsContent] = useState<string | null>('');
  const { transferInscription } = UseBlockchainCalls();
  const { address } = useData();

  useEffect(() => {
    setDestinationError('');
    const isBns = destination.includes('.');
    if (!isBns) {
      setBnsContent(null);
      if (!validateStacksAddress(destination) && destination.length > 0) {
        setDestinationError('Invalid STX address');
      }
      if (address === destination) {
        setDestinationError('Connected address');
      }
    }
    if (isBns) {
      if (destination.endsWith('.stxs')) {
        resolveStxsName(destination).then((res) => {
          if (res) {
            setDestinationError('');
            setBnsContent(res);
          } else {
            setBnsContent(null);
            setDestinationError('Invalid STXS name');
          }
        });
      } else {
        resolveBnsName(destination).then((res) => {
          if (res) {
            setDestinationError('');
            setBnsContent(res);
          } else {
            setBnsContent(null);
            setDestinationError('Invalid BNS name');
          }
        });
      }
    } else setBnsContent(null);
  }, [destination, address]);

  const onTransfer = () => {
    transferInscription(
      bnsContent ? bnsContent : destination,
      inscriptionHash,
      onSuccess,
      onFailure
    );
  };

  return (
    <BasicModal onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <span className={classes.title}>Transfer sOrdinal</span>
        </div>
        <BasicInput
          onChange={(value) => setDestination(value)}
          value={destination}
          placeHolder={'Enter Address or BNS name'}
          error={destinationError}
          info={shortenText(bnsContent || '')}
          className={classes.destinationInput}
        />
        <div className={classes.buttonWrapper}>
          <BigPurpuleButton
            className={classes.button}
            text="Transfer"
            onClick={onTransfer}
            disabled={destination.length === 0 || destinationError.length > 0}
          />
        </div>
      </div>
    </BasicModal>
  );
};
