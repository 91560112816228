import React from 'react';
import classes from './../InscriptionStyles.module.scss';
import classNames from 'classnames';
import { Skeleton } from '../../Skeleton';
import { Wrapper } from '../../Wrapper';
import { BackButton } from '../../Buttons/BackButton';
import { uniqueId } from 'lodash';

type Props = {
  onExit: () => void;
};
export const InscriptionSkeleton = ({ onExit }: Props) => {
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.topWrapper}>
        <div className={classes.backWrapper}>
          <BackButton onClick={onExit} />
        </div>
        <div className={classNames(classes.inscriptionWrapper)}>
          <div
            className={classNames(classes.imageWrap, classes.imageWrapSkeleton)}
          >
            <div className={classes.placeHolderBg} />
          </div>
          <div className={classes.infoWrap}>
            <div className={classes.infoRow}>
              <div className={classes.name}>
                <Skeleton width="80%" height="32px" />
              </div>
            </div>
            {[0, 1, 2, 3, 4, 5, 6].map((index) => {
              const rnd = Math.floor(Math.random() * 25);
              return (
                <div
                  className={classes.infoRow}
                  key={uniqueId(`skeleton--${index}`)}
                >
                  <div className={classes.infoName}>
                    <Skeleton width={`${50 + rnd}px`} height="20px" />
                  </div>
                  <div className={classes.infoHr} />
                  <div className={classes.infoValue}>
                    <Skeleton width={`${100 - rnd}px`} height="20px" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
