import { openContractCall, useConnect, openSTXTransfer } from '@stacks/connect-react';
import useData from '../hooks/useData';
import { useOkxWallet } from '../hooks/useOkxWallet';
import { SignatureData } from '@stacks/connect';
import { getProvider } from './getProvider';
import brotliPromise from 'brotli-wasm';
import { PROVIDERS, inscriptionContentMapData } from '../constants';
import {Buffer} from 'buffer';
import {
  contractPrincipalCVFromString,
  getInscribeContractAddress,
  getInscribeContractName,
  transformAddressesInputToArray,
} from './utils';
import {
  bufferCVFromString,
  ClarityValue,
  listCV,
  principalCV,
  uintCV,
  bufferCV,
  cvToHex,
} from '@stacks/transactions';
import { ContractCallOptions } from '@stacks/connect';
import { ImagesStateType } from '../types/inscribeTypes';
import { PostConditionMode, makeStandardSTXPostCondition, FungibleConditionCode } from '@stacks/transactions';

export const UseBlockchainCalls = () => {
  const { sign } = useConnect();
  const { signMessageOkxWallet, signTransactionOkxWallet } = useOkxWallet();
  const { stacksNetwork, address } = useData();

  const transferInscription = async (
    destination: string,
    inscriptionHash: string,
    onFinish: () => void,
    onCancel: () => void
  ) => {
    const hexString = Buffer.from('t').toString('hex') + inscriptionHash;
    const memoBuffer = bufferCV(Buffer.from(hexString, 'hex'));
    const functionArgs = [
      principalCV(destination),
      memoBuffer,
    ]
    doContractCall({
      functionArgs,
      postConditionMode: PostConditionMode.Deny,
      contractAddress: getInscribeContractAddress(),
      contractName: getInscribeContractName(),
      functionName: 'transfer-memo-single',
      postConditions: [makeStandardSTXPostCondition(
        address || '',
        FungibleConditionCode.Equal,
        1
      )],
      network: stacksNetwork,
      stxAddress: address || '',
    }, onFinish, onCancel)
  }

  const createOrderTransaction = async (
    destination: string,
    files: ImagesStateType[],
    hasParent: boolean,
    parentHash: string,
    isSingleDestination: boolean,
    onFinish: () => void,
    onCancel: () => void
  ) => {
    const fillAddressesArray = () => {
      const destinations = transformAddressesInputToArray(destination);
      if (isSingleDestination) {
        return Array.from({ length: files.length }, () => destination);
      }
      return destinations;
    };

    const addresses = fillAddressesArray();

    if (addresses.length !== files.length) {
      throw new Error('Incorrect addresses amount');
    }
    if (!address) {
      throw new Error('No wallet connected');
    }
    let functionName = 'sordinals--inscribe-multiple';

    const contentTypes = files.map(file => {
      const extension = file.name.split('.').pop();
      const defaultType = 'text/plain';
      if (!extension) {
        return defaultType;
      }
      return inscriptionContentMapData.find(x => x.fileTypes.includes(extension.toLowerCase()))?.contentType || defaultType;
    })

    const addressesCV = listCV(
      addresses.map((a) =>
        a.includes('.') ? contractPrincipalCVFromString(a) : principalCV(a)
      )
    );

    const brotli = await brotliPromise;
    const compressedFiles = files.map(file => {
      return Buffer.from(brotli.compress(file.dataUint8Array)).toString('binary');
    });

    const fileLengths = compressedFiles.map(file => file.length);
    const mergedCompressedFiles = compressedFiles.reduce((a, b) => a + b, '');
    const encoding = 'br';

    let parentBuffer = bufferCVFromString('');

    if (hasParent) {
      parentBuffer = bufferCV(Buffer.from(parentHash, 'hex'));
    }

    let functionArgs = [
      addressesCV,
      parentBuffer,
      bufferCVFromString(''),
      bufferCVFromString(encoding),
      listCV(contentTypes.map(contentType => bufferCVFromString(contentType))),
      listCV(fileLengths.map(length => uintCV(length))),
      bufferCV(Buffer.from(mergedCompressedFiles, 'binary')),
    ] as ClarityValue[];

    doContractCall({
      contractAddress: getInscribeContractAddress(),
      contractName: getInscribeContractName(),
      functionName,
      functionArgs,
      postConditionMode: PostConditionMode.Deny,
      postConditions: [],
      stxAddress: address,
      network: stacksNetwork,
    }, onFinish, onCancel);
  };

  const doContractCall = async (
    options: ContractCallOptions, 
    onFinish?: () => void,
    onCancel?: () => void,
  ) => {
    const selectedProvider = getProvider();
    if (!selectedProvider)
      return;
    if (selectedProvider === getProvider(PROVIDERS.Okx)) {
      try {
        await signTransactionOkxWallet({
          ...options,
          functionArgs: options.functionArgs.map(x => cvToHex(x as ClarityValue)),
          network: undefined,
        }, 'contract_call');
        onFinish && onFinish();
      } catch (e) {
        onCancel && onCancel();
      }
      return;
    }
    openContractCall({
      ...options,
      onFinish,
      onCancel,
    }, getProvider());
  }

  const signMessage = async (
    message: string,
    onFinish?: (res: SignatureData) => void,
    onError?: () => void
  ) => {
    const selectedProvider = getProvider();
    if (!selectedProvider) return;
    if (selectedProvider === getProvider(PROVIDERS.Okx)) {
      try {
        const response = await signMessageOkxWallet({ message });
        onFinish && onFinish(response);
      } catch (e) {
        onError && onError();
      }
      return;
    }
    sign(
      {
        message: message,
        network: stacksNetwork,
        onFinish: (res) => {
          onFinish && onFinish(res);
        },
        onCancel: () => {
          onError && onError();
        },
      },
      selectedProvider
    );
  };

  return { signMessage, createOrderTransaction, transferInscription };
};
