import { AppConfig, UserSession } from '@stacks/connect';
import { getProvider } from './getProvider';
import { NETWORK, Network, PROVIDERS } from '../constants';

export const getUserSessionData = (): string | null => {
  const selectedProvider = getProvider();
  if (selectedProvider && selectedProvider === getProvider(PROVIDERS.Okx))
    return null;
  const appConfig = new AppConfig(['store_write'], document.location.href);
  const userSession = new UserSession({ appConfig });
  if (userSession.isUserSignedIn()) {
    const stxAddress = userSession.loadUserData().profile.stxAddress;
    return NETWORK === Network.MAINNET
      ? stxAddress.mainnet
      : stxAddress.testnet;
  }
  return '';
};
