import { useState } from 'react';
import useData from '../../hooks/useData';
import { useAddress } from '../../store/address';
import { NETWORK, ProviderInstallLinks, PROVIDERS } from '../../constants';
import { getProvider } from '../../utils/getProvider';
import classes from './ConnectButtonStyles.module.scss';
import { BigPurpuleButton } from '../Buttons/BigPurpuleButton/BigPurpuleButton';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { shortenText } from '../../utils/utils';

type ConnectWalletItem = {
  install: boolean;
  link: string;
  logo: string;
  title: string;
};

type Props = {
  className?: string;
};

export const ConnectButton = ({ className }: Props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [loggedInButtonOpened, setLoggedInButtonOpened] = useState(false);
  const { makeAuthRequest, logout, isAuthorized } = useData();
  const [address] = useAddress();

  const handleWalletClick = async (item: ConnectWalletItem) => {
    const provider = getProvider(item.title as PROVIDERS);
    if (!provider) {
      window.open(item.link);
      return;
    }
    if (item.install && item.link !== '') {
      makeAuthRequest(item.title as PROVIDERS);
    }
  };

  const WalletConnectors = [
    {
      title: 'Leather',
      logo: '/images/providers/leather.svg',
      // @ts-ignore
      install: !!window.HiroProvider || !!window.LeatherProvider,
      link: ProviderInstallLinks.LEATHER,
    },
    {
      title: 'Xverse',
      logo: '/images/providers/xverse.svg',
      // @ts-ignore
      install: !!window.XverseProviders?.StacksProvider,
      link: ProviderInstallLinks.XVERSE,
    },
    {
      title: 'OKX',
      logo: '/images/providers/okx.png',
      install: !!getProvider(PROVIDERS.Okx),
      link: ProviderInstallLinks.OKX,
    },
  ];

  return (
    <div className={classes.component}>
      {loggedInButtonOpened && (
        <div
          onClick={() => setLoggedInButtonOpened(false)}
          className={classes.overflowTransparent}
        />
      )}
      {isAuthorized && (
        <div className={classes.logWrapperBtn}>
          <div
            onClick={() => setLoggedInButtonOpened((prevState) => !prevState)}
            className={classes.loggedInWrapper}
          >
            <div className={classes.loggedInContent}>
              <span className={classes.walletAddress}>
                {shortenText(address || '')}
              </span>
              <span className={classes.currentNetwork}>{NETWORK}</span>
            </div>
            <img
              style={
                loggedInButtonOpened ? {} : { transform: 'rotate(180deg)' }
              }
              className={classes.shevron}
              src="/images/shevron.svg"
              alt=""
            />
          </div>
          {loggedInButtonOpened && (
            <div className={classes.loggedInMenu}>
              <button className={classes.logoutButton} onClick={logout}>
                <img width={20} height={20} src="/images/power.svg" alt="" />
                <span className={classes.disconnectText}>Disconnect</span>
              </button>
            </div>
          )}
        </div>
      )}
      {!isAuthorized && (
        <BigPurpuleButton
          onClick={() => setMenuOpened((prevState) => !prevState)}
          text={'Connect wallet'}
          className={classNames(classes.connectWalletButton, className || '')}
        />
      )}
      {menuOpened && (
        <div
          onClick={() => setMenuOpened(false)}
          className={classes.overflow}
        />
      )}
      {menuOpened && (
        <div className={classes.menuContainer}>
          <div className={classes.menuHeader}>
            <span className={classes.menuTitle}>Connect wallet</span>
            <img
              src="/images/icon-close.svg"
              alt="Close"
              onClick={() => setMenuOpened(false)}
              className={classes.link}
            />
          </div>
          <div className={classes.menuButtons}>
            {WalletConnectors.map((item) => {
              console.log(item);
              return (
                <div
                  key={uniqueId(item.title)}
                  className={classNames(
                    classes.connectMenuItem2,
                    !item.install && classes.notinstall
                  )}
                  onClick={() => {
                    handleWalletClick(item);
                    setMenuOpened(false);
                  }}
                >
                  <img src={item.logo} alt="" />
                  <span>{item.title}</span>
                  {!item.install && (
                    <a
                      href={item.link}
                      target={'_blank'}
                      className={classes.installButton}
                      rel="noreferrer"
                    >
                      <span>Install</span>
                      <img src="/images/arrow.svg" alt="" />
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
