import React from 'react';
import classes from './Pagination.module.scss';
import { Wrapper } from '../Wrapper/Wrapper';
import classNames from 'classnames';

type Props = {
  currentPage: number;
  pageCount: number;
  onChange: (page: number) => void;
};
export const Pagination = ({ currentPage, pageCount, onChange }: Props) => {
  if (pageCount === 1) return <></>;

  const pageItems: number[] = [1];
  for (let i = currentPage; i <= currentPage + 2; i += 1) {
    if (i > 1 && i < pageCount) pageItems.push(i);
  }
  pageItems.push(pageCount);
  const sortPages = Array.from(new Set(pageItems));
  sortPages.sort((a, b) => a - b);

  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.paginatorWrapper}>
          <div
            className={classNames(
              classes.page,
              classes.first,
              currentPage === 0 && classes.disabled
            )}
            onClick={() => currentPage > 0 && onChange(currentPage - 1)}
          >
            <img src="/images/arrow-left.svg" alt="Prev" />
          </div>
          {sortPages.map((page, index) => {
            return page !== currentPage + 1 ? (
              <React.Fragment key={`page-${page}`}>
                {((index === 1 && page > 2) ||
                  (index + 1 === sortPages.length &&
                    sortPages[index - 1] < pageCount - 1)) && (
                  <div className={classes.div}>...</div>
                )}
                <div
                  className={classes.page}
                  onClick={() => onChange(page - 1)}
                >
                  {page}
                </div>
              </React.Fragment>
            ) : (
              <div key={`page-${page}`} className={classes.currentPageBg}>
                <div className={classes.currentPageBlackBg}>
                  <div
                    className={classNames(classes.page, classes.currentPage)}
                  >
                    {page}
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className={classNames(
              classes.page,
              classes.last,
              currentPage === pageCount - 1 && classes.disabled
            )}
            onClick={() =>
              currentPage < pageCount - 1 && onChange(currentPage + 1)
            }
          >
            <img src="/images/arrow-right.svg" alt="Next" />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
