import { InscribeDestination } from '../../../../constants';
import { ImagesStateType } from '../../../../types/inscribeTypes';
import { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import { validateStacksAddress } from 'micro-stacks/crypto';

export const validateAddresses = (
  address: string,
  value: string,
  destinationType: InscribeDestination,
  filesInBase64: ImagesStateType[],
  setDestinationError: Dispatch<SetStateAction<string>>
) => {
  if (isEmpty(value) && InscribeDestination.CONNECTED !== destinationType) return setDestinationError('');
  switch (destinationType) {
    case InscribeDestination.CONNECTED:
      setDestinationError('');
      break;
    case InscribeDestination.SINGLE:
      if (!validateStacksAddress(value)) {
        setDestinationError('Invalid address');
      } else {
        setDestinationError('');
      }
      break;
    case InscribeDestination.MULTIPLE:
      if (!value.includes(',')) {
        if (!validateStacksAddress(value)) {
          setDestinationError(`Invalid address ${value}`);
        } else if (filesInBase64.length > 1) {
          setDestinationError(
            "The number of addresses doesn't match the quantity of items."
          );
        } else {
          setDestinationError('');
        }
      } else {
        //validate address
        const values = value
          .split(',')
          .map((value) => value.trim())
          .filter(Boolean);
        const invalidValues = values.filter(
          (value) => !validateStacksAddress(value)
        );
        if (!isEmpty(invalidValues)) {
          const errorMessage =
            invalidValues.length === 1
              ? `Invalid address ${invalidValues[0]} is invalid`
              : `Invalid addresses ${invalidValues.join(', ')}`;

          setDestinationError(errorMessage);
        } else if (values.some((value) => value.trim() === address)) {
          setDestinationError('Cannot inscribe to the same connected wallet');
        } else {
          setDestinationError('');
        }
        //validate length of addresses
        if (values.length !== filesInBase64.length) {
          setDestinationError(
            "The number of addresses doesn't match the quantity of items."
          );
        } else {
          setDestinationError('');
        }
        console.log(values.length !== filesInBase64.length);
      }

      break;
  }
};
