import React, { Dispatch, SetStateAction } from 'react';
import classes from './AddParentInscriptionStyles.module.scss';
import { BasicInput } from '../../../../components/Inputs/BasicInput/BasicInput';
import { Switch } from '../Switch/Switch';

type Props = {
  parentId: string;
  onChangeParentId: (parentId: string) => void;
  label: string;
  hasParentId: boolean;
  setHasParentId: Dispatch<SetStateAction<boolean>>;
  error: string;
};
export const AddParentInscription = ({
  onChangeParentId,
  parentId,
  label,
  hasParentId,
  error,
  setHasParentId,
}: Props) => {
  return (
    <div className={classes.component}>
      <div className={classes.switchWrapper}>
        <Switch checked={hasParentId} onCheck={setHasParentId} />
        <span className={classes.switchLabel}>{label}</span>
      </div>
      {hasParentId && (
        <div className={classes.wrapper}>
          <BasicInput
            onChange={onChangeParentId}
            value={parentId}
            placeHolder={'Parent inscription id'}
            error={error}
          />
        </div>
      )}
    </div>
  );
};
