import React, { useState } from 'react';
import classes from './Dropdown.module.scss';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import { DropdownMenuItem } from '../../types';

type Props = {
  selectedItem: DropdownMenuItem;
  dropdownItems: DropdownMenuItem[];
  onChange: (val: DropdownMenuItem) => void;
  hideLabelInList?: boolean;
  className?: string;
};
export const Dropdown = ({
  selectedItem,
  dropdownItems,
  onChange,
  hideLabelInList,
  className,
}: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { label, text } = selectedItem;
  return (
    <div className={classes.component}>
      <div
        className={classNames(className || '', classes.selectedWrapper)}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
        }}
      >
        <div className={classes.caption}>
          {label && <span className={classes.label}>{label}</span>}
          <span className={classes.title}>{text}</span>
        </div>
        <img
          src="/images/icon-dropdown.svg"
          alt=""
          className={classNames(classes.arrow, showMenu && classes.arrowOpened)}
        />
      </div>
      {showMenu && (
        <>
          <div className={classes.menuBg} onClick={() => setShowMenu(false)} />
          <div className={classes.menuItems}>
            {dropdownItems.map((item) => (
              <div
                key={uniqueId(item.text)}
                onClick={() => {
                  onChange(item);
                  setShowMenu(false);
                }}
                className={classes.menuItem}
              >
                <div className={classes.caption}>
                  {!hideLabelInList && item.label && (
                    <span className={classes.label}>{item.label}</span>
                  )}
                  <span className={classes.title}>{item.text}</span>
                </div>
                {text === item.text && <img src="/images/check.svg" alt="" />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
