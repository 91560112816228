import React from 'react';
import classes from './Header.module.scss';
import { Wrapper } from '../Wrapper';
import { ConnectButton } from '../ConnectButton';
import { NavigationRoutes, NavMenuItems } from '../../constants';
import { ReactComponent as ExploreIcon } from './icons/ExploreIcon.svg';
import { ReactComponent as InscribeIcon } from './icons/InscribeIcon.svg';
import { ReactComponent as MarketplaceIcon } from './icons/MarketplaceIcon.svg';
import { ReactComponent as BalanceIcon } from './icons/BalanceIcon.svg';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

export const Header: React.FC = () => {
  const location = useLocation();

  const menuItems = [
    {
      title: NavMenuItems.EXPLORE,
      icon: <ExploreIcon />,
      link: NavigationRoutes.EXPLORE,
      hide: false,
    },
    {
      title: NavMenuItems.INSCRIBE,
      icon: <InscribeIcon />,
      link: NavigationRoutes.INSRIBE,
      hide: false,
    },
    {
      title: NavMenuItems.MARKETPLACE,
      icon: <MarketplaceIcon />,
      link: NavigationRoutes.MARKETPLACE,
      hide: true,
    },
    {
      title: NavMenuItems.MYBALANCE,
      icon: <BalanceIcon />,
      link: NavigationRoutes.MYBALANCE,
      hide: false,
    },
  ];

  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.logoWrapper}>
          <a href="/">
            <img src="/images/logo.png" width={41} height={41} alt="" />
          </a>
          <a href="/">
            <h3 className={classes.txtLogo}>Sordinals</h3>
          </a>

          <a
            href="https://stx20.com/"
            className={classes.stx20Link}
            target="_blank"
            rel="noreferrer"
          >
            STX20 <img src="/images/icon-link.svg" alt="" />
          </a>
        </div>
        <div className={classes.menuItems}>
          {menuItems
            .filter((menuItem) => !menuItem.hide)
            .map((menuItem) => (
              <NavLink
                key={uniqueId(menuItem.title)}
                to={menuItem.link}
                className={classNames(
                  classes.menuItemWrapper,
                  location.pathname === menuItem.link
                    ? classes.menuItemWrapperActive
                    : ''
                )}
              >
                {menuItem.icon} {menuItem.title}
              </NavLink>
            ))}
        </div>
        <div className={classes.buttonsWrapper}>
          <ConnectButton />
        </div>
      </Wrapper>
    </div>
  );
};
