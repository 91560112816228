import React from 'react';
import classes from './FileUploadErrorStyles.module.scss';
import { uniqueId } from 'lodash';
import { FileError } from 'react-dropzone';

type Props = {
  file: File;
  errors: FileError[];
  onClose: (filename: string) => void;
};
export const FileUploadError = ({ file, errors, onClose }: Props) => {
  return (
    <div
      className={classes.container}
      key={uniqueId(file.name)}
      onClick={() => onClose(file.name)}
    >
      <button className={classes.closeError}>
        <img src="/images/icon-close.svg" width={16} height={16} alt="" />
      </button>
      <img src="/images/error-icon.svg" width={40} height={40} alt="" />
      <div className={classes.wrapper}>
        <div className={classes.errorMessageWrapper}>
          {errors.map((fileError) => (
            <div className={classes.errorMessage} key={fileError.code}>
              {fileError.message}
            </div>
          ))}
        </div>
        <span className={classes.filename}>{file.name}</span>
      </div>
    </div>
  );
};
