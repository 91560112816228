import React from 'react';
import classes from './NoResults.module.scss';
import { Wrapper } from '../Wrapper/Wrapper';

type Props = {
  title?: string;
};
export const NoResults = ({ title = 'No results found' }: Props) => {
  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <img src="/images/icon-zoom.svg" alt="" />
        <h3 className={classes.alertTitle}>{title}</h3>
      </Wrapper>
    </div>
  );
};
