import React, { useEffect, useMemo, useState } from 'react';
import classes from './InscriptionStyles.module.scss';
import { Wrapper } from '../Wrapper';
import { IInscription } from '../../Axios/responseTypes';
import { BackButton } from '../Buttons/BackButton';
import { shortenText } from '../../utils/utils';
import { CopyButton } from '../Buttons/CopyButton';
import { countStxAmount } from '../../helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getInscription,
  getInscriptionByHash,
  getInscriptionsByParent,
} from '../../Axios/AxiosInstance';
import {
  CreateInscriptionStatus,
  NavigationRoutes,
  STX_DECIMALS,
} from '../../constants';
import { InscriptionSkeleton } from './components';
import { useInscriptionType } from '../InscriptionCard';
import { InscriptionContentPreview } from '../InscriptionContentPreview';
import { InscriptionType } from '../../types';
import { getExplorerBlockUrl, getExplorerUrl } from '../../utils/getExlorerUrl';
import { Inscriptions } from '../Inscriptions';
import { renderFileSize } from '../../utils/renderFileSize';
import { BigPurpuleButton } from '../Buttons/BigPurpuleButton';
import { TransferModal } from '../Modals';
import { useAddress } from '../../store/address';
import { SuccessFailModal } from '../../pages/Inscribe/components/SuccessFailModal/SuccessFailModal';
import { isUndefined } from 'lodash';
type Props = {};
export const Inscription = ({}: Props) => {
  const [address] = useAddress();
  const { inscriptionId } = useParams<{
    inscriptionId: string;
    initialContent: string;
    initialType: InscriptionType;
  }>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { initialContent, initialType } = state || {};
  const [isLoading, setLoading] = useState(false);
  const [isTransferModal, setTransferModal] = useState(false);
  const [childrenCount, setChildrenCount] = useState(10);
  const [inscription, setInscription] = useState<IInscription>();
  const { inscriptionContent, inscriptionType } =
    useInscriptionType(inscription);
  const [transferStatus, setTransferStatus] =
    useState<CreateInscriptionStatus | null>(null);
  const [parentId, setParentId] = useState('');

  useEffect(() => {
    const fetchInscription = async (id: string) => {
      setLoading(true);
      try {
        const res = await getInscription(id);
        const inscriptionResult = res;
        // console.log('inscriptionResult', inscriptionResult);
        setInscription(inscriptionResult);
        return inscriptionResult;
        // setLoading(false);
      } catch (error) {
        navigate(NavigationRoutes.EXPLORE, {
          state: { search: id, is404: true },
        });
        console.error('Error fetching inscription:', error);
      }
    };
    const fetchChildren = async (hash: string | undefined) => {
      if (!hash) {
        setChildrenCount(0);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const childrenResponse = await getInscriptionsByParent(hash, {});
        setChildrenCount(childrenResponse.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inscription:', error);
      }
    };
    !isUndefined(inscriptionId) &&
      fetchInscription(inscriptionId).then((inscription) => {
        if (inscription?.parentHash) {
          getInscriptionByHash(inscription.parentHash).then(
            (parentInscription) => {
              setParentId(parentInscription.id);
            }
          );
        }
        fetchChildren(inscription?.inscriptionHash);
      });
  }, [inscriptionId, navigate]);

  const handleClick = () => {
    if (window.history.state && window.history.state.idx > 0) {
      state.fromSearch
        ? navigate(-1 * (1 + (inscriptionId?.length || 1)))
        : navigate(-1);
    } else {
      navigate(NavigationRoutes.EXPLORE, { state: {} });
    }
  };

  const displayedContent = initialContent || inscriptionContent;
  const displayedType = initialType || inscriptionType;

  const handleOpenLink = (id: string | undefined) => {
    if (!id) return;
    window.open(getExplorerUrl(id));
  };

  const handleOpenBlockLink = (id: string | undefined) => {
    if (!id) return;
    window.open(getExplorerBlockUrl(id));
  };

  const handleOpenInscriptionLink = (id: string | undefined) => {
    if (!id) return;
    navigate('/inscription/' + id);
  };

  const createdDate = new Date(inscription?.creationTime || '');
  const fileSize = useMemo(() => {
    if (!inscription) return 0;
    return Number(inscription.fileSize);
  }, [inscription]);

  const handleTransfer = () => {
    setTransferModal(true);
  };
  const isOwner = useMemo(() => {
    return inscription?.owner === address;
  }, [inscription?.owner, address]);

  return (
    <div className={classes.component}>
      {isLoading ? (
        <InscriptionSkeleton onExit={handleClick} />
      ) : (
        <div className={classes.root}>
          <Wrapper className={classes.wrapper}>
            <div className={classes.topWrapper}>
              <div className={classes.backWrapper}>
                <BackButton onClick={handleClick} />
              </div>
              <div className={classes.inscriptionWrapper}>
                <div className={classes.imageWrap}>
                  {inscription && displayedContent && displayedType && (
                    <InscriptionContentPreview
                      id={inscription?.id}
                      contentType={inscription.contentType}
                      content={displayedContent}
                      isSmall={false}
                      type={displayedType}
                      isNsfw={inscription.isNsfw}
                    />
                  )}
                </div>
                <div className={classes.infoWrap}>
                  <div className={classes.infoColumn}>
                    <div className={classes.infoRow}>
                      <div className={classes.name}>
                        <span>Inscription</span> #{inscription?.id}
                      </div>
                    </div>
                    <div className={classes.infoRow}>
                      {/* <div className={classes.name}>
                        <CopyButton
                          title="Copy inscription data"
                          address={window.location.href}
                          className={
                            isOwner
                              ? classes.copyButtonHalf
                              : classes.copyButton
                          }
                        />
                      </div> */}
                      {isOwner && (
                        <BigPurpuleButton
                          className={classes.transferButton}
                          text="Transfer"
                          onClick={handleTransfer}
                        />
                      )}
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Inscription id</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      <span>{shortenText(inscription?.inscriptionHash)}</span>
                      <CopyButton
                        address={inscription?.inscriptionHash || ''}
                      />
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Owned by</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      {shortenText(inscription?.owner)}
                      <CopyButton address={inscription?.owner || ''} />
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>File type</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      {inscription?.contentType}
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>File size</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      {renderFileSize(fileSize)}
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Created</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      {createdDate.toLocaleDateString('en-US')}
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Creation block</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      <span
                        className={classes.link}
                        onClick={() =>
                          handleOpenBlockLink(inscription?.createdBlockHash)
                        }
                      >
                        {shortenText(inscription?.createdBlockHash)}
                      </span>
                      <CopyButton
                        address={inscription?.createdBlockHash || ''}
                      />
                    </div>
                  </div>
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Creation transaction</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      <span
                        className={classes.link}
                        onClick={() => handleOpenLink(inscription?.createdTxId)}
                      >
                        {shortenText(inscription?.createdTxId)}
                      </span>
                      <CopyButton address={inscription?.createdTxId || ''} />
                    </div>
                  </div>
                  {inscription?.parentHash && (
                    <div className={classes.infoRow}>
                      <div className={classes.infoName}>Parent</div>
                      <div className={classes.infoHr} />
                      <div className={classes.infoValue}>
                        <span
                          className={classes.link}
                          onClick={() => handleOpenInscriptionLink(parentId)}
                        >
                          {shortenText(parentId)}
                        </span>
                        <CopyButton address={parentId || ''} />
                      </div>
                    </div>
                  )}
                  <div className={classes.infoRow}>
                    <div className={classes.infoName}>Creation fee</div>
                    <div className={classes.infoHr} />
                    <div className={classes.infoValue}>
                      {countStxAmount(
                        Number(inscription?.txFee || 0),
                        STX_DECIMALS
                      )}{' '}
                      STX
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>

          {childrenCount > 0 && inscription?.inscriptionHash && (
            <>
              <div className={classes.hr} />
              <Wrapper className={classes.wrapper}>
                <div className={classes.bottomWrapper}>
                  <Inscriptions
                    rootId={inscription.id}
                    rootHash={inscription.inscriptionHash}
                  />
                </div>
              </Wrapper>
            </>
          )}
          {isTransferModal && (
            <TransferModal
              onClose={() => setTransferModal(false)}
              onSuccess={() => {
                setTransferStatus(CreateInscriptionStatus.SUCCESS);
                setTransferModal(false);
              }}
              onFailure={() => setTransferStatus(CreateInscriptionStatus.FAIL)}
              inscriptionHash={inscription?.inscriptionHash || ''}
            />
          )}
        </div>
      )}
      {!!transferStatus && (
        <SuccessFailModal
          onClose={() => setTransferStatus(null)}
          isSuccess={transferStatus === CreateInscriptionStatus.SUCCESS}
          successTitle="Transfer transaction initiated"
          failureTitle="Transfer transaction failed"
          onlyTitle
        />
      )}
    </div>
  );
};
