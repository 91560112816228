import React from 'react';
import classes from './AddedFileStyles.module.scss';
import classNames from 'classnames';
import { ImagesStateType } from '../../../../../../types/inscribeTypes';
import { shortenText } from '../../../../../../utils/utils';
import { BYTES_IN_KB } from '../../../../../../constants';
import { renderFileSize } from '../../../../../../utils/renderFileSize';

type Props = {
  file: ImagesStateType;
  index: number;
  onDelete: () => void;
};

export const AddedFile = ({ file, onDelete, index }: Props) => {
  return (
    <div className={classes.component}>
      <div className={classNames(classes.itemIndex, classes.roundButton)}>
        {index + 1}
      </div>
      <div className={classes.wrapper}>
        <span className={classes.filename}>
          {file.name.length > 13 ? shortenText(file.name, 4, 7) : file.name}
        </span>
        <span className={classes.fileSize}>{renderFileSize(file.size)}</span>
      </div>
      <div
        className={classNames(classes.delete, classes.roundButton)}
        onClick={(event) => {
          event.stopPropagation();
          onDelete();
        }}
      >
        <img src="/images/icon-close.svg" width={16} height={16} />
      </div>
    </div>
  );
};
