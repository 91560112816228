export function shortenAddress(address = '', slice = 5, endSlice = 3) {
  if (address.length < 11) {
    return address;
  }

  return `${address.slice(0, slice)}...${address.slice(-endSlice)}`;
}

export function removeEmptyFields(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ''
    )
  );
}

export const displayFullAmount = (amount: number, decimals: number) =>
  amount.toFixed(decimals).replace(/\.?0+$/, '');

export const countStxAmount = (amount: number, decimals: number) =>
  amount / Math.pow(10, decimals);
