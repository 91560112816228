import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import './globalStyles.scss';
import { AppConfig, UserSession } from '@stacks/connect';
import { useAddress } from './store/address';
import { Network, NETWORK } from './constants';
import { Connect } from '@stacks/connect-react';
import { WalletConnection } from './context/WalletConnection';
import { BrowserRouter as Router } from 'react-router-dom';
import { Navigation } from './Navigation';

function App() {
  const appConfig = new AppConfig(
    ['store_write', 'publish_data'],
    document.location.href
  );
  const userSession = new UserSession({ appConfig });
  const [, setAddress] = useAddress();

  const authOptions = {
    manifestPath: '/static/manifest.json',
    redirectTo: '/',
    userSession,
    onFinish: (res: any) => {
      const userData = res.userSession.loadUserData().profile.stxAddress;
      setAddress(
        NETWORK === Network.MAINNET ? userData.mainnet : userData.testnet
      );
    },
    appDetails: {
      name: 'sOrdinals',
      icon: '/images/logo.svg',
    },
  };

  return (
    <Connect authOptions={authOptions}>
      <WalletConnection>
        <Router>
          <Navigation />
        </Router>
      </WalletConnection>
    </Connect>
  );
}

export default App;
