import { SignatureData, STXTransferOptions } from '@stacks/connect';
import {ContractCallOptions} from '@stacks/connect'
import { getProvider } from '../utils/getProvider';
import { PROVIDERS } from '../constants';

export const useOkxWallet = () => {
  const requestAddressOtxWallet = async (): Promise<{ address: string }> => {
    return await getProvider(PROVIDERS.Okx).connect();
  };

  const authenticateOkxWallet = async (): Promise<{
    address: string;
    publicKey: string;
  }> => {
    return await getProvider(PROVIDERS.Okx).connect();
  };

  const signMessageOkxWallet = async (data: {
    message: string;
  }): Promise<SignatureData> => {
    return await getProvider(PROVIDERS.Okx).signMessage(data);
  };

  const signTransactionOkxWallet = async (
    transaction: ContractCallOptions,
    txType: 'contract_call' | 'token_transfer',
  ): Promise<{
    txHash: string;
    signature: string;
  }> => {
    return getProvider(PROVIDERS.Okx).signTransaction({
      ...transaction,
      txType,
    });
  };

  return {
    authenticateOkxWallet,
    signMessageOkxWallet,
    signTransactionOkxWallet,
    requestAddressOtxWallet,
  };
};
