import React from 'react';
import classes from './BalanceStyles.module.scss';
import { Wrapper } from '../../components/Wrapper';
import { ConnectCard } from '../../components/ConnectCard';
import useData from '../../hooks/useData';
import { Inscriptions } from '../../components/Inscriptions';

type Props = {};
export const Balance = ({}: Props) => {
  const { isAuthorized } = useData();
  return (
    <Wrapper className={classes.component}>
      {!isAuthorized ? <ConnectCard /> : <Inscriptions myBalance />}
    </Wrapper>
  );
};
