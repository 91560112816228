import React from 'react';
import classes from './BasicInputStyles.module.scss';
import classNames from 'classnames';

type Props = {
  onChange: (value: string) => void;
  value: string;
  placeHolder: string;
  error?: string;
  info?: string;
  className?: string;
};
export const BasicInput = ({
  value,
  placeHolder,
  onChange,
  error,
  info,
  className,
}: Props) => {
  return (
    <div className={classNames(classes.wrapper, className)}>
      <input
        onChange={(event) => onChange(event.target.value)}
        value={value}
        placeholder={placeHolder}
        className={classes.input}
      />
      {error && <span className={classes.error}>{error}</span>}
      {info && <span className={classes.info}>{info}</span>}
    </div>
  );
};
