import React, { useEffect } from 'react';
import styles from './MainPageStyles.module.scss';
import { Outlet } from 'react-router-dom';
import useData from '../hooks/useData';
import { useAddress } from '../store/address';
import { LocalStorageKeys } from '../constants';
import { Header } from '../components/Header/Header';
import { getProvider } from '../utils/getProvider';
import { Footer } from '../components/Footer/Footer';
import { UseBlockchainCalls } from '../utils/useBlockchainCalls';

export const MainPage = () => {
  const { setIsAuthorized } = useData();
  const [address] = useAddress();
  const { signMessage } = UseBlockchainCalls();

  useEffect(() => {
    const isAuthorized = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    if (!address || isAuthorized) return;
    if (!isAuthorized && localStorage.getItem(LocalStorageKeys.WALLET_TYPE)) {
      const walletProvider = getProvider();

      if (walletProvider) {
        setIsAuthorized(true);
        // signMessage(CONNECT_MESSAGE, (res: any) => {
        //   return login({
        //     publicKey: res.publicKey,
        //     signature: res.signature,
        //     address,
        //     setIsAuthorized,
        //   });
        // });
      }
    }
  }, [address]);

  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
