import React, { useEffect, useState } from 'react';
import classes from './InscriptionCardStyles.module.scss';
import classNames from 'classnames';
import { IInscription } from '../../Axios/responseTypes';
import { SimpleButton } from '../Buttons/SimpleButton';
import { InscriptionType } from '../../types';
import { getInscriptionContentType } from '../../utils/getInscriptionContentType';
import { InscriptionContentPreview } from '../InscriptionContentPreview';
import { useAddress } from '../../store/address';

type Props = {
  inscription: IInscription;
  onClick: (initialContent: string, initialType: InscriptionType) => void;
  onBuy: () => void;
  myBalance?: boolean;
  className?: string;
};

export const useInscriptionType = (inscription: IInscription | undefined) => {
  const [type, setType] = useState<InscriptionType>(InscriptionType.UNKNOWN);
  const [inscriptionContent, setInscriptionContent] = useState('');

  useEffect(() => {
    if (!inscription) return;
    const contentType = getInscriptionContentType(
      inscription.contentType,
      inscription.contentEncoding
    );
    setType(contentType);
    if (
      contentType === InscriptionType.MARKDOWN ||
      contentType === InscriptionType.TEXT ||
      contentType === InscriptionType.CODE
    ) {
      fetch(inscription.fileUrl)
        .then(async (response) => setInscriptionContent(await response.text()))
        .catch((e) => {
          console.log('fetch preview error', e);
        });
    } else {
      setInscriptionContent(inscription.fileUrl);
    }
  }, [inscription]);

  return { inscriptionType: type, inscriptionContent };
};

export const InscriptionCard = ({
  inscription,
  onClick,
  onBuy,
  myBalance = false,
  className,
}: Props) => {
  const { inscriptionType, inscriptionContent } =
    useInscriptionType(inscription);
  const [address] = useAddress();
  const isOwner = React.useMemo(() => {
    return inscription?.owner === address;
  }, [inscription?.owner, address]);

  return (
    <div
      className={classNames(classes.component, className || '')}
      onClick={() => onClick(inscriptionContent, inscriptionType)}
    >
      <div className={classes.imageWrapper}>
        {inscriptionType && (
          <InscriptionContentPreview
            isSmall
            contentType={inscription.contentType}
            id={inscription.id}
            content={inscriptionContent}
            type={inscriptionType}
            isNsfw={inscription.isNsfw}
          />
        )}
        <span className={classes.type}>{inscriptionType}</span>
      </div>
      <div className={classes.infoWrapper}>
        <span className={classes.caption}>#{inscription.id}</span>
        {/* <div className={classes.priceWrapper}>
            <img src="/images/stx.png" alt="" className={classes.icon} />
            {displayFullAmount(inscription.price, 6)}
          </div> */}
      </div>
      {myBalance && (
        <div className={classes.buttonWrapper}>
          <SimpleButton text="Transfer" onClick={onBuy} />
        </div>
      )}
    </div>
  );
};
