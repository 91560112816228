import React, { Dispatch, SetStateAction } from 'react';
import classes from './SwitchStyles.module.scss';
import classNames from 'classnames';

type Props = {
  checked: boolean;
  onCheck: Dispatch<SetStateAction<boolean>>;
};
export const Switch = ({ checked, onCheck }: Props) => {
  return (
    <div
      className={classNames(
        classes.toggleWrapper,
        checked ? classes.toggleWrapperChecked : ''
      )}
      onClick={() => onCheck((prevState) => !prevState)}
    >
      <div
        className={classNames(
          classes.toggle,
          checked ? classes.toggleChecked : ''
        )}
      ></div>
    </div>
  );
};
