import React from 'react';
import classes from './SimpleButtonStyles.module.scss';
import classNames from 'classnames';
import { Loader } from '../../Loader';

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
};
export const SimpleButton = ({
  text,
  onClick,
  disabled,
  className,
  loading,
}: Props) => {
  return (
    <button
      className={classNames(
        classes.component,
        loading && classes.componentLoading,
        className || ''
      )}
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick();
      }}
      disabled={disabled}
    >
      {loading && <Loader small noMargin />}
      <span className={classes.text}>{text}</span>
    </button>
  );
};
