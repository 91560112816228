import React from 'react';
import classes from './BackButtonStyles.module.scss';
import classNames from 'classnames';

type Props = {
  onClick: () => void;
  className?: string;
};
export const BackButton = ({ onClick, className }: Props) => {
  return (
    <div
      className={classNames(classes.component, className || '')}
      onClick={onClick}
    >
      <img src="/images/icon-back.svg" alt="Back" />
    </div>
  );
};
