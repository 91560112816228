import classNames from 'classnames';
import { InscriptionType } from '../../types';
import classes from './InscriptionContentPrevewStyles.module.scss';
import prismjs from 'prismjs';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useEffect } from 'react';

export const InscriptionContentPreview: React.FC<{
  id: string;
  content: string;
  type: InscriptionType;
  isSmall: boolean;
  contentType: string;
  isNsfw: boolean;
}> = ({ content, type, id, isSmall, contentType, isNsfw }) => {
  const sizeClass = isSmall ? classes.smallItem : classes.bigItem;

  const highlithed =
    type === InscriptionType.CODE
      ? prismjs.highlight(content, prismjs.languages.javascript, 'javascript')
      : '';

  const fontName = 'font' + id;

  useEffect(() => {
    const fontFace = new FontFace(fontName, `url(${content})`);
    document.fonts.add(fontFace);
  }, [id, content]);

  if (isNsfw) {
    return (
      <div className={sizeClass}>
        <div className={classes.nsfw}>
          <img
            className={classNames(classes.image, classes.imageDefault)}
            src="/images/hide.svg"
            alt=""
          />
          <span>NSFW content</span>
        </div>
      </div>
    );
  }

  return (
    <div className={sizeClass}>
      {type === InscriptionType.UNKNOWN && (
        <div className={classes.bgDefault}>
          <img
            className={classNames(classes.image, classes.imageDefault)}
            src="/images/default-ordinal.svg"
            alt=""
          />
        </div>
      )}
      {type === InscriptionType.IMAGE && (
        <img
          className={classNames(classes.image, sizeClass)}
          src={content}
          alt=""
        />
      )}
      {type === InscriptionType.AUDIO && (
        <audio controls className={sizeClass}>
          <source src={content} type={contentType} />
          Your browser does not support this audio type.
        </audio>
      )}
      {type === InscriptionType.VIDEO && (
        <>
          <video
            className={classNames(
              classes.video,
              sizeClass,
              isSmall && classes.videoPreview
            )}
            controls={!isSmall}
          >
            <source src={content} />
            Your browser does not support this video type.
          </video>
          {isSmall && (
            <div className={classes.overlayMiddle}>
              <img src="/images/play.svg" alt="" />
            </div>
          )}
        </>
      )}
      {type === InscriptionType.PDF && (
        <embed
          src={content}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
      {type === InscriptionType.HTML && (
        <iframe
          sandbox="allow-scripts"
          title={id}
          src={content}
          className={classNames(classes.iframe, sizeClass)}
        />
      )}
      {type === InscriptionType.TEXT && (
        <div
          className={classNames(
            classes.text,
            sizeClass,
            isSmall && classes.textSmall
          )}
        >
          {isSmall ? content.slice(0, 150) : content}
        </div>
      )}
      {type === InscriptionType.FONT && (
        <div
          className={classNames(
            classes.text,
            sizeClass,
            isSmall && classes.textSmall
          )}
          style={{ fontFamily: fontName }}
        >
          Sphinx of black quartz, judge my vow.
        </div>
      )}
      {type === InscriptionType.MARKDOWN && (
        <div className={classNames(classes.text, sizeClass)}>
          <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
        </div>
      )}
      {type === InscriptionType.CODE && (
        <div className={classNames(classes.text, sizeClass)}>
          <pre className={classNames(classes.codeWrapper, sizeClass)}>
            <code
              className={classNames(classes.codeContent, 'language-javascript')}
            >
              <div dangerouslySetInnerHTML={{ __html: highlithed }}></div>
            </code>
          </pre>
        </div>
      )}
      {type === InscriptionType.MODEL && (
        // @ts-ignore
        <model-viewer
          alt=""
          src={content}
          shadow-intensity="1"
          camera-controls
          auto-rotate
        />
      )}
      {isSmall &&
        (type === InscriptionType.MODEL || type === InscriptionType.HTML) && (
          <div className={classes.overlay}></div>
        )}
      {type === InscriptionType.DOC && (
        <iframe
          src={'https://view.officeapps.live.com/op/embed.aspx?src=' + content}
          className={classNames(classes.iframe, sizeClass)}
        />
      )}
    </div>
  );
};
