import { getProvider } from '../utils/getProvider';
import { useEffect } from 'react';
import { useConnect } from '@stacks/connect-react';
import { useAtom } from 'jotai/index';
import { useOkxWallet } from './useOkxWallet';
import { useAddress } from '../store/address';
import { getStacksNetwork } from '../utils/utils';
import { authAtom } from '../store/auth';
import { LocalStorageKeys, NETWORK, Network, PROVIDERS } from '../constants';

const useData = () => {
  const { doAuth } = useConnect();
  const [isAuthorized, setIsAuthorized] = useAtom(authAtom);
  const { authenticateOkxWallet, signTransactionOkxWallet } = useOkxWallet();
  const [address, setAddress] = useAddress();

  useEffect(() => {
    if (isAuthorized) return;
    // const authToken = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    // if (authToken) setIsAuthorized(true);
    const authToken = localStorage.getItem(LocalStorageKeys.SESSION);
    if (!authToken) return;
    const stxAddresses = JSON.parse(authToken).userData?.profile.stxAddress;
    if (stxAddresses) setIsAuthorized(true);
  }, [isAuthorized]);

  const makeAuthRequest = (provider: PROVIDERS) => {
    if (provider) {
      if (provider === PROVIDERS.Okx) {
        authenticateOkxWallet().then((response) => {
          setAddress(response.address);
        });
      } else {
        const selectedProvider = getProvider(provider);
        doAuth({}, selectedProvider);
      }
      localStorage.setItem(LocalStorageKeys.WALLET_TYPE, provider);
      localStorage.setItem(LocalStorageKeys.NETWORK, Network.MAINNET);
    }
  };

  const stacksNetwork = getStacksNetwork(NETWORK);

  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return {
    makeAuthRequest,
    address,
    logout,
    isAuthorized,
    setIsAuthorized,
    stacksNetwork,
  };
};

export default useData;
