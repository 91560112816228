import React from 'react';
import classes from './SuccessFailModalsSyles.module.scss';
import { BasicModal } from '../../../../components/BasicModal/BasicModal';
import { BigPurpuleButton } from '../../../../components/Buttons/BigPurpuleButton';

type Props = {
  onClose: () => void;
  isSuccess: boolean;
  successTitle?: string;
  failureTitle?: string;
  description?: string;
  onlyTitle?: boolean;
};
export const SuccessFailModal = ({ onClose, isSuccess, successTitle, failureTitle, description, onlyTitle }: Props) => {
  return (
    <BasicModal onClose={onClose}>
      <div className={classes.wrapper}>
        <img
          src={isSuccess ? '/images/success.svg' : '/images/fail.svg'}
          alt=""
          width={80}
          height={80}
          className={classes.image}
        />
        <div className={classes.contentWrapper}>
          <span className={classes.title}>
            {isSuccess
              ? successTitle || 'Inscription transaction created'
              : failureTitle || 'Inscription transaction failed'}
          </span>
          {!onlyTitle && <span className={classes.text}>
            {isSuccess
              ? 'This inscription will appear on your balance within 6 blocks after transaction confirmation'
              : 'Please check destination address and your wallet balance'}
          </span>}
        </div>
        <BigPurpuleButton
          className={classes.button}
          text={'Got It'}
          onClick={onClose}
        />
      </div>
    </BasicModal>
  );
};
