import React, { PropsWithChildren, useEffect } from 'react';
import { PROVIDERS } from '../constants';
import { useAddress } from '../store/address';
import { getUserSessionData } from '../utils/getUserSessionData';
import { getProvider } from '../utils/getProvider';
import { useOkxWallet } from '../hooks/useOkxWallet';

export const WalletConnection: React.FC<PropsWithChildren> = ({ children }) => {
  const [address, setAddress] = useAddress();
  const { requestAddressOtxWallet } = useOkxWallet();
  useEffect(() => {
    const listener = () => {
      const selectedProvider = getProvider();
      if (!selectedProvider) {
        return;
      }
      if (selectedProvider === getProvider(PROVIDERS.Okx)) {
        requestAddressOtxWallet().then((response: any) =>
          setAddress(response.address)
        );
        return;
      } else {
        const stxAddress = getUserSessionData();
        setAddress(stxAddress);
      }
    };
    window.addEventListener('load', listener);
  }, []);

  return <>{children}</>;
};
