import React, { useCallback, useEffect, useState } from 'react';
import classes from './InscriptionsStyles.module.scss';
import { Wrapper } from '../Wrapper';
import {
  getInscriptions,
  getInscriptionsByOwner,
  getInscriptionsByParent,
} from '../../Axios/AxiosInstance';
import { IInscription, IPagination } from '../../Axios/responseTypes';
import { Pagination } from '../Pagination';
import { removeEmptyFields } from '../../helpers';
import { DropdownMenuItem, InscriptionType } from '../../types';
import {
  CreateInscriptionStatus,
  NavigationRoutes,
  SORT_BY,
} from '../../constants';
import { InscriptionCard, SkeletonInscriptionCard } from '../InscriptionCard';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import { Dropdown } from '../Dropdown';
import { SearchInput } from '../SearchInput';
import { NoResults } from '../NoResults';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { validateStacksAddress } from 'micro-stacks/crypto';
import { useAddress } from '../../store/address';
import { TransferModal } from '../Modals';
import { SuccessFailModal } from '../../pages/Inscribe/components/SuccessFailModal/SuccessFailModal';
import {
  getContentTypes,
  resolveBnsName,
  resolveStxsName,
} from '../../utils/utils';
import { Filters } from '../Filters';

type Props = {
  rootId?: string;
  rootHash?: string;
  myBalance?: boolean;
};
export const Inscriptions = ({
  rootId = '0',
  myBalance = false,
  rootHash,
}: Props) => {
  const LIMIT = 24;
  const [address] = useAddress();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortMenus: DropdownMenuItem[] = [
    {
      text: SORT_BY.LATEST_TO_RECENT,
      label: 'Sort:',
      filter: '-1',
    },
    {
      text: SORT_BY.RECENT_TO_LATEST,
      label: 'Sort:',
      filter: '1',
    },
  ];

  const navigate = useNavigate();
  const { state } = useLocation();

  const [search, setSearch] = useState(
    myBalance
      ? address
      : rootId === '0'
        ? state?.search || searchParams.get('search') || ''
        : ''
  );
  const [searchVal, setSearchVal] = useState(
    myBalance
      ? address
      : rootId === '0'
        ? state?.search || searchParams.get('search') || ''
        : ''
  );
  const [searchError, setSearchError] = useState('');
  const [is404, set404] = useState(
    rootId === '0' ? state?.is404 || false : false
  );
  const [orderSort, setOrderSort] = useState<DropdownMenuItem>(
    rootId === '0'
      ? sortMenus.find((item) => searchParams.get('sort') === item.filter) ||
          sortMenus[0]
      : sortMenus[0]
  );
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    rootId === '0' ? searchParams.get('page') || 0 : 0
  );
  const [pagination, setPagination] = useState<IPagination>();
  const [inscriptions, setInscriptions] = useState<IInscription[]>();
  const [isLoading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(
    searchParams.get('filter') || 'No text'
  );

  const [hashTransferModal, setHashTransferModal] = useState('');
  const [transferStatus, setTransferStatus] =
    useState<CreateInscriptionStatus | null>(null);

  const getData = useCallback(
    async (searchVal: string) => {
      // if (
      //   !/^[A-Z0-9]+$/.test(searchVal.toUpperCase()) &&
      //   searchVal.length > 0
      // ) {
      //   return;
      // }

      const filtersQuery = removeEmptyFields({
        ...{ order: orderSort.filter },
        ...{ page: currentPage.toString() },
        ...{ limit: LIMIT },
      }) as Record<string, string>;

      setLoading(true);
      setInscriptions([]);
      if (is404) {
        setLoading(false);
        return;
      }
      if (rootId === '0' && !rootHash) {
        if (searchVal.includes('.')) {
          const bnsFuction = searchVal.endsWith('.stxs')
            ? resolveStxsName
            : resolveBnsName;

          bnsFuction(searchVal).then(async (res) => {
            if (res) {
              const inscriptionResponse = await getInscriptionsByOwner(
                res,
                filtersQuery
              );
              setPagination(inscriptionResponse);
              setInscriptions(inscriptionResponse.data);
              setCount(inscriptionResponse.count || 0);
              setLoading(false);
            } else {
              setLoading(false);
              setSearchError(
                searchVal.endsWith('.stxs')
                  ? 'Invalid STXS name'
                  : 'Invalid BNS name'
              );
              return;
            }
          });
        } else {
          if (validateStacksAddress(searchVal)) {
            const inscriptionResponse = await getInscriptionsByOwner(
              searchVal,
              filtersQuery
            );
            setPagination(inscriptionResponse);
            setInscriptions(inscriptionResponse.data);
            setCount(inscriptionResponse.count || 0);
            setLoading(false);
          } else if (searchVal.length === 0) {
            const contentTypes = getContentTypes(selectedFilter);
            const inscriptionResponse = await getInscriptions(
              filtersQuery,
              contentTypes
            );
            setPagination(inscriptionResponse);
            setInscriptions(inscriptionResponse.data);
            setCount(inscriptionResponse.count || 0);
            setLoading(false);
          } else {
            if (!/^[0-9]+$/.test(searchVal.toUpperCase())) {
              setLoading(false);
              setSearchError('Wrong address');
              return;
            }
            setLoading(false);
            navigate(`${NavigationRoutes.INSCRIPTION}${searchVal}`, {
              state: {
                is404: false,
                fromSearch: true,
              },
            });
          }
        }
      } else {
        if (searchVal.length === 0 && rootHash) {
          const inscriptionResponse = await getInscriptionsByParent(
            rootHash,
            filtersQuery
          );
          setPagination(inscriptionResponse);
          setInscriptions(inscriptionResponse.data);
          setCount(inscriptionResponse.count || 0);
          setLoading(false);
        } else {
          if (!/^[0-9]+$/.test(searchVal.toUpperCase())) {
            setLoading(false);
            setSearchError('Wrong sOrdinal ID');
            return;
          }
          setLoading(false);
          navigate(`${NavigationRoutes.INSCRIPTION}${searchVal}`, {
            state: {
              is404: false,
            },
          });
        }
      }
    },
    [
      currentPage,
      is404,
      navigate,
      orderSort.filter,
      rootHash,
      rootId,
      selectedFilter,
    ]
  );

  useEffect(() => {
    getData(searchVal);
  }, [orderSort, currentPage, searchVal, getData, selectedFilter]);

  const handleChange = (search: string) => {
    setSearchError('');
    setSearch(search);
  };

  const handleSearch = (search: string) => {
    set404(false);
    setSearchVal(search);
  };

  const handleTransfer = (inscriptionHash: string) => {
    setHashTransferModal(inscriptionHash);
  };

  return (
    <div className={classes.component}>
      <Wrapper
        className={classNames(
          classes.wrapper,
          rootId !== '0' && classes.childrenWrapper
        )}
      >
        <>
          {!myBalance && rootId === '0' && (
            <Filters
              selected={selectedFilter}
              onClick={(filter) => {
                setCurrentPage(0);
                setSelectedFilter(filter);
                searchParams.set('filter', filter);
                setSearchParams(searchParams);
              }}
            />
          )}
        </>
        <div className={classes.headWrapper}>
          {myBalance ? (
            <div className={classes.captionWrap}>
              <span className={classes.count}>{count}</span>
              <span className={classes.caption}>Inscriptions available</span>
            </div>
          ) : rootId === '0' ? (
            <div className={classes.captionWrap}>
              <span className={classes.count}>{count}</span>
              <span className={classes.caption}>Inscriptions found</span>
            </div>
          ) : (
            <div className={classes.captionWrap}>
              <span className={classes.count}>
                Child inscriptions ({count})
              </span>
            </div>
          )}
          <div className={classes.actionsWrap}>
            {!myBalance && rootId === '0' && (
              <SearchInput
                name="search"
                placeholder="Wallet address or sOrdinal ID"
                value={search}
                onChange={(val) => {
                  handleChange(val);
                  searchParams.set('search', val);
                  searchParams.set('page', '0');
                  setSearchParams(searchParams);
                }}
                onSearch={handleSearch}
                error={searchError}
              />
            )}
            <Dropdown
              dropdownItems={sortMenus}
              selectedItem={orderSort}
              onChange={(val) => {
                setCurrentPage(0);
                setOrderSort(val);
                if (val.filter) {
                  searchParams.set('sort', val.filter?.toString());
                  searchParams.set('page', '0');
                  setSearchParams(searchParams);
                }
              }}
              className={classes.dropdown}
            />
          </div>
        </div>
        {isLoading ? (
          <div className={classes.inscriptionsWrapper}>
            <div
              className={classNames(
                classes.inscriptionList,
                classes.inscriptionListSkeleton
              )}
            >
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <SkeletonInscriptionCard key={uniqueId(`skeleton-${index}`)} />
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.inscriptionsWrapper}>
            {inscriptions?.length === 0 ? (
              <NoResults />
            ) : (
              <>
                <div className={classes.inscriptionList}>
                  {inscriptions?.map((inscription) => (
                    <InscriptionCard
                      key={inscription.id}
                      inscription={inscription}
                      myBalance={myBalance}
                      onClick={(
                        initialContent: string,
                        initialType: InscriptionType
                      ) =>
                        navigate(
                          `${NavigationRoutes.INSCRIPTION}${inscription.id}`,
                          {
                            state: {
                              initialContent,
                              initialType,
                              is404: false,
                              rootId,
                            },
                          }
                        )
                      }
                      onBuy={() => handleTransfer(inscription.inscriptionHash)}
                    />
                  ))}
                </div>
                <Pagination
                  currentPage={pagination?.page || 0}
                  pageCount={Math.ceil(Number(pagination?.count || 1) / LIMIT)}
                  onChange={(val) => {
                    setCurrentPage(val);
                    searchParams.set('page', val.toString());
                    setSearchParams(searchParams);
                  }}
                />
              </>
            )}
          </div>
        )}
      </Wrapper>
      {hashTransferModal && (
        <TransferModal
          onClose={() => setHashTransferModal('')}
          onSuccess={() => {
            setTransferStatus(CreateInscriptionStatus.SUCCESS);
            setHashTransferModal('');
          }}
          onFailure={() => setTransferStatus(CreateInscriptionStatus.FAIL)}
          inscriptionHash={hashTransferModal}
        />
      )}
      {!!transferStatus && (
        <SuccessFailModal
          onClose={() => setTransferStatus(null)}
          isSuccess={transferStatus === CreateInscriptionStatus.SUCCESS}
          successTitle="Transfer transaction initiated"
          failureTitle="Transfer transaction failed"
          onlyTitle
        />
      )}
    </div>
  );
};
