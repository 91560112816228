export type DropdownMenuItem = {
  text: string;
  label?: string;
  filter?: string;
};

export enum InscriptionType {
  IMAGE = 'Image',
  TEXT = 'Text',
  VIDEO = 'Video',
  GAME = 'Game',
  AUDIO = 'Audio',
  UNKNOWN = 'Unknown',
  CODE = 'Code',
  MODEL = 'Model',
  MARKDOWN = 'Markdown',
  FONT = 'Font',
  HTML = 'HTML',
  PDF = 'PDF',
  DOC = 'Doc',
}
