import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import classes from './InscriptionCardStyles.module.scss';
import classNames from 'classnames';

type Props = {};
export const SkeletonInscriptionCard = () => {
  return (
    <SkeletonTheme baseColor="#10101344" highlightColor="#16161Aee">
      <div className={classNames(classes.component, classes.skeleton)}>
        <div className={classNames(classes.imageWrapper, classes.skeleton)}>
          <Skeleton
            height={140}
            width={140}
            borderRadius={8}
            containerClassName={classes.skeletonImage}
          />
        </div>
        <div className={classes.infoWrapper}>
          <span className={classNames(classes.caption, classes.skeleton)}>
            <Skeleton height={20} width={77} borderRadius={8} />
          </span>
          <span className={classNames(classes.date, classes.skeleton)}>
            <Skeleton height={14} width={66} borderRadius={8} />
          </span>
        </div>
        <Skeleton height={36} width={140} borderRadius={8} />
      </div>
    </SkeletonTheme>
  );
};
