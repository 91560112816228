import React, { useCallback, useEffect, useState } from 'react';
import classes from './InscribeStyles.module.scss';
import classNames from 'classnames';
import {
  CreateInscriptionStatus,
  InscribeDestination,
  InscribeTextType,
  Links,
  MAX_TOTAL_BASE64_SYMBOLS,
} from '../../constants';
import { BasicInput } from '../../components/Inputs/BasicInput/BasicInput';
import { Wrapper } from '../../components/Wrapper';
import { BigPurpuleButton } from '../../components/Buttons/BigPurpuleButton';
import { DropFiles } from './components/DropFiles/DropFiles';
import { ImagesStateType } from '../../types/inscribeTypes';
import { debounce, isEmpty, uniqueId } from 'lodash';
import { UseBlockchainCalls } from '../../utils/useBlockchainCalls';
import { AddParentInscription } from './components/AddParentInscription/AddParentInscription';
import useData from '../../hooks/useData';
import { ConnectButton } from '../../components/ConnectButton';
import { validateAddresses } from './components/DropFiles/utils';
import { SuccessFailModal } from './components/SuccessFailModal/SuccessFailModal';
import { getInscription, getInscriptionByHash } from '../../Axios/AxiosInstance';
import { TextArea } from './components/TextArea';
import { Tabs, TabsComponent } from './components/Tabs';

type Props = {};
export const Inscribe = ({}: Props) => {
  const [destination, setDestination] = useState('');
  const [destinationError, setDestinationError] = useState('');
  const [destinationType, setDestinationType] = useState<InscribeDestination>(
    InscribeDestination.CONNECTED
  );
  const [filesUint8Array, setFilesUint8Array] = useState<ImagesStateType[]>([]);
  const [customFeeError, setCustomFeeError] = useState('');
  const [totalSymbolsError, setTotalSymbolsError] = useState('');
  const [parentError, setParentError] = useState('');
  const [isParentLoading, setParentLoading] = useState(false);
  const { createOrderTransaction } = UseBlockchainCalls();
  const { address, isAuthorized } = useData();
  const [parentId, setParentId] = useState('');
  const [hasParentId, setHasParentId] = useState(false);
  const [parentHash, setParentHash] = useState('');
  const [creationStatus, setCreationStatus] =
    useState<CreateInscriptionStatus | null>(null);

  const [selectTab, setSelectTab] = useState<Tabs>('Files');
  const [textValue, setTextValue] = useState('');
  const [textError, setTextError] = useState('');
  const [textType, setTextType] = useState<InscribeTextType>(
    InscribeTextType.SINGLE
  );

  useEffect(() => {
    // const getFees = async () => {
    //   if (!isEmpty(filesInBase64)) {
    //     const feeTypes = await calculateGasFee(filesInBase64);
    //     setFeeTypes(feeTypes);
    //     setSelectedFee(feeTypes.normal);
    //   }
    // };
    // getFees();

    if (filesUint8Array) {
      const totalBase64Length = filesUint8Array.reduce(
        (acc, item) => acc + item.dataUint8Array.length,
        0
      );
      if (totalBase64Length > MAX_TOTAL_BASE64_SYMBOLS) {
        setTotalSymbolsError('Total file size exceeded');
      } else {
        setTotalSymbolsError('');
      }
    }
  }, [filesUint8Array]);

  //
  // useEffect(() => {
  //   const getTotalFee = async () => {
  //     if (!filesInBase64 || !selectedFee) return;
  //     const total = await countTotalFee(filesInBase64, selectedFee);
  //     setTotalFee(total);
  //   };
  //   getTotalFee();
  // }, [filesInBase64, selectedFee]);

  useEffect(() => {
    validateAddresses(
      address || '',
      destination,
      destinationType,
      filesUint8Array,
      setDestinationError
    );
  }, [destination, destinationType, filesUint8Array, address]);

  const handleSend = () => {
    if (isDisbled || !address) return;
    createOrderTransaction(
      destinationType === InscribeDestination.CONNECTED ? address : destination,
      filesUint8Array,
      hasParentId,
      parentHash,
      destinationType === InscribeDestination.SINGLE ||
        destinationType === InscribeDestination.CONNECTED,
      () => {
        setFilesUint8Array([]);
        setDestination('');
        setParentId('');
        setCreationStatus(CreateInscriptionStatus.SUCCESS);
      },
      () => setCreationStatus(CreateInscriptionStatus.FAIL)
    );
  };

  const fetchParentInscription = async (id: string) => {
    const errorMessage = "Your wallet doesn't possess this Inscription";
    try {
      const response = id.length === 64 ? await getInscriptionByHash(id) : await getInscription(id);
      if (response.owner === address) {
        setParentError('');
        setParentHash(response.inscriptionHash);
      } else {
        setParentError(errorMessage);
      }
    } catch (e) {
      setParentError(errorMessage);
    } finally {
      setParentLoading(false);
    }
  };

  const parentInscriptionDebounce = useCallback(
    debounce(fetchParentInscription, 300),
    []
  );

  const handleChangeText = (txt: string) => {
    setTextError('');
    setTextValue(txt);
  };

  const isDisbled =
    !!destinationError ||
    !!customFeeError ||
    !!totalSymbolsError ||
    // !selectedFee ||
    (!destination && destinationType !== InscribeDestination.CONNECTED) ||
    isParentLoading ||
    (hasParentId && (!parentHash || !!parentError)) ||
    isEmpty(filesUint8Array);

  return (
    <>
      <Wrapper className={classes.component}>
        <div className={classes.headerWrapper}>
          <h2 className={classes.title}>Inscribe sOrdinals</h2>
          <span className={classes.subtitle}>
            New inscriptions will appear on your balance within 6 blocks after
            transaction confirmation
          </span>
          <div className={classes.warning}>
            <img src="/images/warning.svg" alt="" />
            <span>
              We strongly recommend creating collections with a parent-child
              approach. For more information, please refer to the{' '}
              <a
                href={Links.AURANLABS}
                className={classes.docLink}
                target="_blank"
                rel="noreferrer"
              >
                details
              </a>
              .
            </span>
          </div>
          {/*<TabsComponent
            selectedTab={selectTab}
            setSelectedTab={setSelectTab}
            textType={textType}
            setTextType={setTextType}
          />*/}
          {selectTab === 'Files' ? (
            <DropFiles
              filesInBase64={filesUint8Array}
              setFilesInBase64={setFilesUint8Array}
            />
          ) : (
            <TextArea
              textValue={textValue}
              setTextValue={handleChangeText}
              placeHolder={
                textType === InscribeTextType.SINGLE
                  ? 'Any text here'
                  : 'abcd&#13;&#10;1234&#13;&#10;etc …'
              }
              txtError={textError}
            />
          )}
          <div className={classes.destinationWrapper}>
            <div className={classes.radioButtonsWrapper}>
              {Object.values(InscribeDestination).map((destinationItem) => (
                <div key={uniqueId(destinationItem)}>
                  <span
                    className={classNames(
                      classes.radioItem,
                      destinationItem === destinationType
                        ? classes.radioItemActive
                        : ''
                    )}
                    onClick={() => setDestinationType(destinationItem)}
                  >
                    <div className={classes.radioDongle}></div>
                    {destinationItem}
                  </span>
                </div>
              ))}
            </div>
            {destinationType === InscribeDestination.MULTIPLE && (
              <span className={classes.multipleInputTitle}>
                Add addresses in one line, separate them with ','
              </span>
            )}
            {InscribeDestination.CONNECTED !== destinationType && (
              <div className={classes.destinationInputsWrapper}>
                <BasicInput
                  onChange={(value) => setDestination(value)}
                  value={destination}
                  placeHolder={'Destination'}
                  error={destinationError}
                  className={classes.destinationInput}
                />
                {/*<span className={classes.destinationWarning}>*/}
                {/*  We recommend making a parent collection inscription with*/}
                {/*  metadata, while leaving child inscriptions without it to reduce*/}
                {/*  fees*/}
                {/*</span>*/}
                {/*<BasicInput*/}
                {/*  onChange={(value) => setName(value)}*/}
                {/*  value={name}*/}
                {/*  placeHolder={'Name (optional)'}*/}
                {/*  className={classes.shortInput}*/}
                {/*/>*/}
                {/*<BasicInput*/}
                {/*  onChange={(value) => setDescription(value)}*/}
                {/*  value={name}*/}
                {/*  placeHolder={'Description (optional)'}*/}
                {/*  className={classes.shortInput}*/}
                {/*/>*/}
              </div>
            )}
            <AddParentInscription
              parentId={parentId}
              onChangeParentId={(parentId) => {
                setParentLoading(true);
                parentInscriptionDebounce(parentId);
                setParentId(parentId);
              }}
              label={'Add parent inscription'}
              hasParentId={hasParentId}
              setHasParentId={setHasParentId}
              error={parentError}
            />
          </div>
          {/*{feeTypes && !totalSymbolsError && !isEmpty(filesInBase64) && (*/}
          {/*  <ChoseGas*/}
          {/*    selectedFee={selectedFee}*/}
          {/*    feeTypes={feeTypes}*/}
          {/*    onSetFee={(fee) => setSelectedFee(fee)}*/}
          {/*    onCustomFeeError={(error: string) => setCustomFeeError(error)}*/}
          {/*    customFeeError={customFeeError}*/}
          {/*    totalFee={totalFee}*/}
          {/*  />*/}
          {/*)}*/}
          {!!totalSymbolsError && (
            <span className={classes.error}>{totalSymbolsError}</span>
          )}
          {isAuthorized ? (
            <BigPurpuleButton
              className={classes.submitButton}
              text={'Submit'}
              onClick={() => handleSend()}
              disabled={isDisbled}
            />
          ) : (
            <ConnectButton className={classes.submitButton} />
          )}
        </div>
      </Wrapper>
      {!!creationStatus && (
        <SuccessFailModal
          onClose={() => setCreationStatus(null)}
          isSuccess={creationStatus === CreateInscriptionStatus.SUCCESS}
        />
      )}
      {/*{showSubmitModal && selectedFee && !isEmpty(filesInBase64) && (*/}
      {/*  <InscribeModal*/}
      {/*    feeBySize={selectedFee}*/}
      {/*    filesInBase64={filesInBase64}*/}
      {/*    totalFee={totalFee}*/}
      {/*    selectedFee={selectedFee}*/}
      {/*    destination={destination}*/}
      {/*    onClose={() => setShowSubmitModal(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};
