import { InscriptionType } from './types';

export const DEV_URL_STX20 = '';
export const PROD_URL_STX20 = 'https://api.stx20.com/api/v1/';

export enum Network {
  TESTNET = 'Testnet',
  MAINNET = 'Mainnet',
}

export enum PROVIDERS {
  Asigna = 'Asigna',
  Xverse = 'Xverse',
  Leather = 'Leather',
  Okx = 'OKX',
}

export const STX_DECIMALS = 6;
export const NETWORK: Network = Network.MAINNET;

export const inscribeContractAddress = {
  [Network.TESTNET]: '',
  [Network.MAINNET]: 'SP1ZKR93HX7QC5VNE4H64QHX92XCCDPHMB803FKWC',
};

export const inscribeContractName = {
  [Network.TESTNET]: '',
  [Network.MAINNET]: 'sordinals-inscribe',
};

export const IS_PROD = true;

export const HIRO_EXPLORER = 'https://explorer.hiro.so/';

export const NETWORK_FEE = BigInt(100000);
export const SERVICE_FEE = BigInt(200000);

export const BYTES_IN_KB = 1024;

export const MAX_FILE_SIZE = 500 * BYTES_IN_KB;

export const MAX_TOTAL_BASE64_SYMBOLS = 1e6;

// export const MAX_FILE_SIZE = 1;
export enum LocalStorageKeys {
  WALLET_TYPE = 'walletType',
  SESSION = 'blockstack-session',
  NETWORK = 'network',
  JWT_TOKEN = 'token',
  JWT_REFRESH = 'refresh',
}

export enum NavMenuItems {
  EXPLORE = 'Explore',
  INSCRIBE = 'Inscribe',
  MARKETPLACE = 'Marketplace',
  MYORDERS = 'My orders',
  MYBALANCE = 'My Inscriptions',
}

export enum ProviderInstallLinks {
  ASIGNA = 'https://chromewebstore.google.com/detail/asigna-multisig/gopddoinbenlokghakiafackadkbnlid?hl=en',
  LEATHER = 'https://chromewebstore.google.com/detail/leather/ldinpeekobnhjjdofggfgjlcehhmanlj?hl=en',
  XVERSE = 'https://chromewebstore.google.com/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg?hl=en',
  OKX = 'https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge',
}

export enum Links {
  PROTOCOL = 'https://github.com/fess-v/sordinals',
  TWITTER = 'https://twitter.com/stxords/',
  GITHUB = '',
  TELEGRAM = '',
  DISCORD = 'https://discord.gg/stx-20',
  AURANLABS = 'https://auranlabs.gitbook.io/sordinals/inscription/inscribe-collection',
}

export enum SORT_BY {
  LOW_TO_HIGH = 'From Low to High',
  HIGH_TO_LOW = 'From High to Low',
  LATEST_TO_RECENT = 'Newest first',
  RECENT_TO_LATEST = 'Oldest first',
}

export enum NavigationRoutes {
  EXPLORE = '/explore',
  INSCRIPTION = '/inscription/',
  INSRIBE = '/inscribe',
  MARKETPLACE = '/marketplace',
  MYORDERS = '/my-orders',
  MYBALANCE = '/my-balance',
}

export enum InscribeDestination {
  CONNECTED = 'Use connected wallet',
  SINGLE = 'To Single address',
  MULTIPLE = 'To Multiple addresses',
}

export enum CreateInscriptionStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum BotliCipherMode {
  GENERIC = 'generic',
  TEXT = 'text',
}

export enum InscribeTextType {
  SINGLE = 'Single',
  BULK = 'Bulk',
}

export const inscriptionContentMapData = [
  {
    contentType: 'image/apng',
    fileTypes: ['apng'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/avif',
    fileTypes: ['avif'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/jpeg',
    fileTypes: ['jpeg', 'jpg'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/png',
    fileTypes: ['png'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/bmp',
    fileTypes: ['bmp'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/gif',
    fileTypes: ['gif'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/tiff',
    fileTypes: ['tif', 'tiff'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'image/webp',
    fileTypes: ['webp'],
    inscriptionType: InscriptionType.IMAGE,
  },
  {
    contentType: 'application/json',
    fileTypes: ['json'],
    inscriptionType: InscriptionType.TEXT,
  },
  {
    contentType: 'application/pgp-signature',
    fileTypes: ['asc'],
    inscriptionType: InscriptionType.TEXT,
  },
  {
    contentType: 'text/plain',
    fileTypes: [''],
    inscriptionType: InscriptionType.TEXT,
  },
  {
    contentType: 'text/plain;charset=utf-8',
    fileTypes: ['txt'],
    inscriptionType: InscriptionType.TEXT,
  },
  {
    contentType: 'video/mp4',
    fileTypes: ['mp4'],
    inscriptionType: InscriptionType.VIDEO,
  },
  {
    contentType: 'video/webm',
    fileTypes: ['webm'],
    inscriptionType: InscriptionType.VIDEO,
  },
  {
    contentType: 'video/quicktime',
    fileTypes: ['mov'],
    inscriptionType: InscriptionType.VIDEO,
  },
  {
    contentType: 'video/x-ms-asf',
    fileTypes: ['wmv'],
    inscriptionType: InscriptionType.VIDEO,
  },
  {
    contentType: 'video/x-msvideo',
    fileTypes: ['avi'],
    inscriptionType: InscriptionType.VIDEO,
  },
  {
    contentType: 'application/ogg',
    fileTypes: ['ogg'],
    inscriptionType: InscriptionType.AUDIO,
  },
  {
    contentType: 'audio/flac',
    fileTypes: ['flac'],
    inscriptionType: InscriptionType.AUDIO,
  },
  {
    contentType: 'audio/mpeg',
    fileTypes: ['mp3'],
    inscriptionType: InscriptionType.AUDIO,
  },
  {
    contentType: 'audio/wav',
    fileTypes: ['wav'],
    inscriptionType: InscriptionType.AUDIO,
  },
  {
    contentType: 'application/pdf',
    fileTypes: ['pdf'],
    inscriptionType: InscriptionType.PDF,
  },
  {
    contentType: 'model/gltf+json',
    fileTypes: ['gltf'],
    inscriptionType: InscriptionType.MODEL,
  },
  {
    contentType: 'model/gltf-binary',
    fileTypes: ['glb'],
    inscriptionType: InscriptionType.MODEL,
  },
  {
    contentType: 'image/svg+xml',
    fileTypes: ['svg'],
    inscriptionType: InscriptionType.HTML,
  },
  {
    contentType: 'text/html',
    fileTypes: [''],
    inscriptionType: InscriptionType.HTML,
  },
  {
    contentType: 'text/html;charset=utf-8',
    fileTypes: ['html'],
    inscriptionType: InscriptionType.HTML,
  },
  {
    contentType: 'text/css',
    fileTypes: ['css'],
    inscriptionType: InscriptionType.CODE,
  },
  {
    contentType: 'text/javascript',
    fileTypes: ['js'],
    inscriptionType: InscriptionType.CODE,
  },
  {
    contentType: 'text/x-python',
    fileTypes: ['py'],
    inscriptionType: InscriptionType.CODE,
  },
  {
    contentType: 'application/x-javascript',
    fileTypes: [''],
    inscriptionType: InscriptionType.CODE,
  },
  {
    contentType: 'application/yaml',
    fileTypes: ['yaml', 'yml'],
    inscriptionType: InscriptionType.CODE,
  },
  {
    contentType: 'font/otf',
    fileTypes: ['otf'],
    inscriptionType: InscriptionType.FONT,
  },
  {
    contentType: 'font/ttf',
    fileTypes: ['ttf'],
    inscriptionType: InscriptionType.FONT,
  },
  {
    contentType: 'font/woff',
    fileTypes: ['woff'],
    inscriptionType: InscriptionType.FONT,
  },
  {
    contentType: 'font/woff2',
    fileTypes: ['woff2'],
    inscriptionType: InscriptionType.FONT,
  },
  {
    contentType: 'text/markdown',
    fileTypes: [''],
    inscriptionType: InscriptionType.MARKDOWN,
  },
  {
    contentType: 'text/markdown;charset=utf-8',
    fileTypes: ['md'],
    inscriptionType: InscriptionType.MARKDOWN,
  },
];
