import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { Inscriptions } from './components/Inscriptions';
import { Inscription } from './components/Inscription';
import { NavigationRoutes } from './constants';
import { Inscribe } from './pages/Inscribe/Inscribe';
import { Balance } from './pages/Balance';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';


export const Navigation = () => {
  const navigateToDefault = <Navigate to={NavigationRoutes.EXPLORE} replace />;

  ReactGA.initialize('G-LLNJXQZ2V2');
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<MainPage />}>
        <Route index path="/" element={navigateToDefault} />
        <Route
          index
          path={NavigationRoutes.EXPLORE}
          element={<Inscriptions />}
        />
        <Route
          index
          path={`${NavigationRoutes.INSCRIPTION}:inscriptionId`}
          element={<Inscription />}
        />

        <Route index path={NavigationRoutes.INSRIBE} element={<Inscribe />} />
        <Route index path={NavigationRoutes.MYBALANCE} element={<Balance />} />

        <Route path="*" element={navigateToDefault} />
      </Route>
    </Routes>
  );
};
