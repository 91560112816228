import React from 'react';
import classes from './Footer.module.scss';
import { Wrapper } from '../Wrapper/Wrapper';
import { Links } from '../../constants';

export const Footer = () => {
  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.crWrapper}>
          by{' '}
          {/* <a
            href={Links.AURANLABS}
            target="_blank"
            className={classes.authorLink}
            rel="noreferrer"
          > */}
          <span className={classes.authorLink}>Auran Labs</span>
          {/* </a> */}
        </div>
        <div className={classes.socialsWrapper}>
          {Links.TWITTER && (
            <a
              href={Links.TWITTER}
              target="_blank"
              className={classes.social}
              rel="noreferrer"
            >
              <img src="/images/icon-twitter.svg" alt="" />
            </a>
          )}
          {Links.DISCORD && (
            <a
              href={Links.DISCORD}
              target="_blank"
              className={classes.social}
              rel="noreferrer"
            >
              <img src="/images/icon-discord.svg" alt="" />
            </a>
          )}
          {Links.TELEGRAM && (
            <a
              href={Links.TELEGRAM}
              target="_blank"
              className={classes.social}
              rel="noreferrer"
            >
              <img src="/images/icon-telegram.svg" alt="" />
            </a>
          )}
          {Links.GITHUB && (
            <a
              href={Links.GITHUB}
              target="_blank"
              className={classes.social}
              rel="noreferrer"
            >
              <img src="/images/icon-github.svg" alt="" />
            </a>
          )}
        </div>
      </Wrapper>
    </div>
  );
};
