import React from 'react';
import classes from './SearchInput.module.scss';
import classNames from 'classnames';

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onSearch: (val: string) => void;
  error?: string;
};
export const SearchInput = ({
  name,
  placeholder,
  value,
  onChange,
  onSearch,
  error,
}: Props) => {
  return (
    <div
      className={classNames(classes.component, error && classes.componentError)}
    >
      <img src="/images/icon-search.svg" alt="" className={classes.icon} />
      <input
        value={value}
        type="text"
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        className={classes.input}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={(event) => {
          if (!error && event.key === 'Enter') {
            onSearch(value);
          }
        }}
      />
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
};
