import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classes from './DropFilesStyles.module.scss';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ImagesStateType } from '../../../../types/inscribeTypes';
import { isEmpty, uniqueId } from 'lodash';
import classNames from 'classnames';
import { AddedFile } from './components/AddedFile/AddedFile';
import { BYTES_IN_KB, MAX_FILE_SIZE, inscriptionContentMapData } from '../../../../constants';
import { FileUploadError } from './components/FileUploadError/FileUploadError';
import { isBase64GLTF, isBase64Markdown } from '../../../../utils/isBase64Gltf';
import { Buffer } from 'buffer';


type Props = {
  filesInBase64: ImagesStateType[];
  setFilesInBase64: Dispatch<SetStateAction<ImagesStateType[]>>;
};

const sizeValidator = (file: File) => {
  if (file.size > MAX_FILE_SIZE)
    return {
      code: 'file-too-large',
      message: `Too big file size`,
    };
  const extension = file.name.split('.').pop();
  const extensionError = {
    code: 'file-extension-wrong',
    message: `Invalid file type`,
  }
  if (!extension) {
    return extensionError;
  }
  const contentType = inscriptionContentMapData.find(x => x.fileTypes.includes(extension.toLowerCase()))?.contentType;
  if (!contentType) {
    return extensionError;
  }
  return null;
};

export const DropFiles = ({ setFilesInBase64, filesInBase64 }: Props) => {
  const [fileErrors, setFileErrors] = useState<FileRejection[]>([]);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({ validator: sizeValidator });

  useEffect(() => {
    if (!isEmpty(fileRejections)) setFileErrors(fileRejections);
  }, [fileRejections]);

  useEffect(() => {
    const loadFiles = async () => {
      if (isEmpty(acceptedFiles)) return [];

      try {
        const imagesDataArray = await Promise.all(
          acceptedFiles.map(async (file) => {
            const fileUrl = URL.createObjectURL(file);

            const response = await fetch(fileUrl);
            const data = await response.blob();

            return new Promise<ImagesStateType>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const arrayBuffer = reader.result;
                const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
                resolve({
                  name: file.name,
                  type: file.type,
                  dataUint8Array: uint8Array,
                  size: file.size,
                });
              };
              reader.readAsArrayBuffer(data);
            });
          })
        );
        const imagesData = imagesDataArray.flat() as ImagesStateType[];
        setFilesInBase64((prevState) => {
          const combinedData = [...prevState, ...imagesData];

          const uniqueData = combinedData.filter((value, index, self) => {
            return (
              index ===
              self.findIndex(
                (item) => item.name === value.name && item.size === value.size
              )
            );
          });

          return uniqueData;
        });
      } catch (error) {
        console.error('Error loading files:', error);
        return [];
      }
    };

    loadFiles();
  }, [acceptedFiles]);

  const deleteItem = (file: ImagesStateType) =>
    setFilesInBase64((prevState) =>
      prevState.filter(
        (prevStateItem) =>
          prevStateItem.name !== file.name && prevStateItem.size !== file.size
      )
    );

  const closeErrorMessage = (filename: string) => {
    setFileErrors((prevState) =>
      prevState.filter((fileError) => fileError.file.name !== filename)
    );
  };

  return (
    <>
      <div
        {...getRootProps({
          className: classNames(classes.container),
        })}
      >
        <input {...getInputProps()} />
        {isEmpty(filesInBase64) ? (
          <div className={classes.contentWrapper}>
            <img
              src="/images/drag-drop-icon.svg"
              width={40}
              height={40}
              alt=""
              className=""
            />
            <div className={classes.titleWrapper}>
              <span className={classes.title}>
                Choose files or drag & drop here
              </span>
              <span className={classes.subTitle}>
                {`.jpg, .webp, .png, .gif, .txt, .mp3, .mp4(h264). - ${
                  MAX_FILE_SIZE / BYTES_IN_KB
                }kb max`}
              </span>
              <span className={classes.subTitle}>
                The interface will soon be updated to handle unlimited file sizes.
              </span>
            </div>
          </div>
        ) : (
          <>
            {filesInBase64.map((file, index) => (
              <AddedFile
                index={index}
                file={file}
                onDelete={() => deleteItem(file)}
                key={uniqueId(file.size.toString())}
              />
            ))}
            <div className={classes.cloudWhenHaveItems}>
              <img
                src="/images/drag-drop-icon.svg"
                width={32}
                height={32}
                alt=""
              />
            </div>
          </>
        )}
      </div>
      {!isEmpty(fileErrors) && (
        <div className={classes.errorsWrapper}>
          {fileErrors.map(({ file, errors }) => (
            <FileUploadError
              key={uniqueId(file.size.toString())}
              errors={errors}
              file={file}
              onClose={() => closeErrorMessage(file.name)}
            />
          ))}
        </div>
      )}
    </>
  );
};
