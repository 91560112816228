import React from 'react';
import classes from './TextAreaStyles.module.scss';
import classNames from 'classnames';

type Props = {
  textValue: string;
  setTextValue: (value: string) => void;
  placeHolder?: string;
  txtError?: string;
  className?: string;
};
export const TextArea = ({
  textValue,
  setTextValue,
  placeHolder = 'Any text here',
  txtError,
  className,
}: Props) => {
  return (
    <div className={classNames(classes.component, className || '')}>
      <textarea
        onChange={(event) => setTextValue(event.target.value)}
        className={classes.input}
        placeholder={placeHolder}
      >
        {textValue}
      </textarea>
    </div>
  );
};
