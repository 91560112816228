import {
  inscribeContractAddress,
  inscribeContractName,
  NETWORK,
  Network,
  IS_PROD,
  PROD_URL_STX20,
  DEV_URL_STX20,
  inscriptionContentMapData,
} from '../constants';
import { StacksMainnet, StacksNetwork, StacksTestnet } from '@stacks/network';
import { contractPrincipalCV } from '@stacks/transactions';
import { InscriptionType } from '../types';

export const getStacksNetwork = (network: Network): StacksNetwork => {
  switch (network) {
    case Network.MAINNET:
      return new StacksMainnet();
    case Network.TESTNET:
      return new StacksTestnet();
  }
};

export function shortenText(address = '', slice = 5, endSlice = 3) {
  if (address.length < 11) {
    return address;
  }

  return `${address.slice(0, slice)}...${address.slice(-endSlice)}`;
}

export const amountInString = (amount: bigint, tokenDecimals: number) => {
  if (amount > Number.MAX_SAFE_INTEGER) {
    const amountBigIntString = amount.toString();
    const index = amountBigIntString.length - tokenDecimals;

    return (
      amountBigIntString.slice(0, index) +
      '.' +
      amountBigIntString.slice(index)
    ).replace(/0+$/, '');
  }

  const amountAsNumber = Number(amount) / Math.pow(10, tokenDecimals);

  return amountAsNumber.toString();
};

export const amountInBigInt = (amount: number, tokenDecimals: number) => {
  const isNotInteger = amount % 1 !== 0;

  if (isNotInteger) {
    return (
      (BigInt(
        Math.round(
          Math.pow(10, tokenDecimals) * Number(amount.toFixed(tokenDecimals))
        )
      ) *
        BigInt(Math.pow(10, tokenDecimals))) /
      BigInt(Math.round(Math.pow(10, tokenDecimals)))
    );
  }

  return BigInt(amount) * BigInt(Math.pow(10, tokenDecimals));
};

export const validateNonIntegerValue = (value: string) =>
  !/[^0-9.]/.test(value);

export const transformAddressesInputToArray = (addresses: string) => {
  if (addresses.includes(',')) {
    return Array.from(
      addresses
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)
    );
  }
  return [addresses];
};

export const contractPrincipalCVFromString = (s: string) => {
  const [a, b] = s.split('.');

  return contractPrincipalCV(a, b);
};

export const getInscribeContractAddress = () => {
  return inscribeContractAddress[NETWORK];
};

export const getInscribeContractName = () => {
  return inscribeContractName[NETWORK];
};

export const resolveBnsName = (
  // network: StacksNetwork,
  name: string
): Promise<string | null> => {
  return fetch(`${new StacksMainnet().coreApiUrl}/v1/names/${name}`)
    .then((r) => r.json())
    .then((r) => {
      if (r.error) {
        return null;
      }

      return r.address;
    });
};

export const resolveStxsName = (name: string): Promise<string | null> => {
  const url = IS_PROD ? PROD_URL_STX20 : DEV_URL_STX20;
  return fetch(`${url}domain/${name}`)
    .then((r) => r.json())
    .then((r) => {
      if (!r.success) {
        return null;
      }

      return r.data.owner;
    });
};

export const getContentTypes = (tag: string) => {
  if (tag === 'All') return [];
  if (tag === 'GIF') return ['image/gif'];
  if (tag === 'No text') {
    const arr = inscriptionContentMapData
      .filter((item) => item.inscriptionType !== InscriptionType.TEXT)
      .map((item) => item.contentType);
    return arr;
  } else {
    const arr = inscriptionContentMapData
      .filter((item) => item.inscriptionType === tag)
      .map((item) => item.contentType);
    return arr;
  }
};
