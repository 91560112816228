import axios, { AxiosInstance } from 'axios';
import {
  IInscription,
  InscriptionResponse,
  ResponseWithPagination,
} from './responseTypes';
import { IS_PROD, LocalStorageKeys } from '../constants';
import { DEV_URL, PROD_URL } from './constants';

export const getTokens = () => {
  return {
    authToken: localStorage.getItem(LocalStorageKeys.JWT_TOKEN),
    refreshToken: localStorage.getItem(LocalStorageKeys.JWT_REFRESH),
  };
};

const SOrdinalsIndexerAxiosInstance: AxiosInstance = axios.create({
  baseURL: IS_PROD ? PROD_URL : DEV_URL,
});

export const getInscription = async (
  inscriptionId: string
): Promise<IInscription> => {
  return (
    await SOrdinalsIndexerAxiosInstance.get<InscriptionResponse>(
      `/inscriptions/${inscriptionId}`,
      {}
    )
  ).data as any;
};

export const getInscriptionByHash = async (
  hash: string
): Promise<IInscription> => {
  return (
    await SOrdinalsIndexerAxiosInstance.get<InscriptionResponse>(
      `/inscriptions/hash/${hash}`,
      {}
    )
  ).data as any;
};

export const getInscriptionsByOwner = async (
  address: string,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IInscription[]>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await SOrdinalsIndexerAxiosInstance.get(
      `/inscriptions/owner/${address}?${urlParams}`,
      {}
    )
  ).data as any;
};

export const getInscriptionsByParent = async (
  parentHash: string,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IInscription[]>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await SOrdinalsIndexerAxiosInstance.get(
      `/inscriptions/parent/${parentHash}?${urlParams}`,
      {}
    )
  ).data as any;
};

export const getInscriptions = async (
  filter: Record<string, string>,
  filters: string[]
): Promise<ResponseWithPagination<IInscription[]>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await SOrdinalsIndexerAxiosInstance.post(`/inscriptions?${urlParams}`, {
      contentType: filters,
    })
  ).data as any;
};
