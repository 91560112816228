import React from 'react';
import classes from './BasicModalStyles.module.scss';
import classNames from 'classnames';

type Props = {
  children: JSX.Element;
  className?: string;
  onClose: () => void;
};
export const BasicModal = ({ children, className, onClose }: Props) => {
  return (
    <>
      <div className={classes.component} onClick={onClose}></div>
      <div className={classNames(classes.wrapper, className || '')}>
        <div className={classes.contentWrapper}>
          {children}
          <div className={classes.close} onClick={onClose}>
            <img src="/images/icon-close.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
