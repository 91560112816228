import React from 'react';
import classes from './ConnectCardStyles.module.scss';
import classNames from 'classnames';
import { ConnectButton } from '../ConnectButton';

type Props = {
  className?: string;
};
export const ConnectCard = ({ className }: Props) => {
  return (
    <div className={classNames(classes.component, className || '')}>
      <img src="/images/connect.svg" alt="Connect" />
      <div className={classes.textWrapper}>
        <div className={classes.title}>Connect your wallet</div>
      </div>
      <div className={classes.buttonWrapper}>
        <ConnectButton />
      </div>
    </div>
  );
};
