import { HIRO_EXPLORER, NETWORK } from "../constants"

const chainPart = '?chain=' + NETWORK.toLocaleLowerCase();

export const getExplorerUrl = (id: string) => {
    return HIRO_EXPLORER + 'txid/' + id + chainPart;
}

export const getExplorerBlockUrl = (id: string) => {
    return HIRO_EXPLORER + 'block/' + id + chainPart;
}